import React from "react"
import Layout from "../components/layout"
import config from "../utils/siteConfig"
import Button from "../components/Atoms/Button/Button"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faEye, faChevronRight } from "@fortawesome/free-solid-svg-icons"

export default function Sustainability({ data }) {
    debugger;
    const page = data.allContentfulZSustainability.edges[0].node

    const Bold = ({ children }) => <span className="bold">{children}</span>
    //const Text = ({ children }) => <p className="align-center">{children}</p>;

    const options = {
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="heading1">{children}</h1>
            ),
            [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="heading2">{children}</h2>
            ),
            [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="heading3">{children}</h3>
            ),
            [BLOCKS.HEADING_4]: (node, children) => (
                <h4 className="h4-st">{children}</h4>
            ),
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                <img src={`https:${node.data.target.fields.file["en-US"].url}`} />
            ),
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="linkGreen p-medium3">{children}</p>
            ),
            [BLOCKS.UL_LIST]: (node, children) => {
                return (
                    <ul
                        style={{
                            listStyle: 'disc',
                            lineHeight: '35px',
                            marginLeft: '1rem',
                        }}
                    >
                        {children.map((item) => (
                            <li key={item.key}>{item.props.children[0].props.children[0]}</li>
                        ))}
                    </ul>
                );
            },
            //[BLOCKS.UL_LIST]: (node, children) => (
            //    <span className="d-inline-block bulletSpan">{children}</span>
            //),

        },
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderText: (text) =>
            text
                .replace(/\u2028/g, "")
                .split("\n")
                .flatMap((text, i) => [i > 0 && <br />, text])
    }

    const {
        id,
        metaData,
        stHHd,
        stHHd2,
        stHImage,
        stHRtxt,
        stS1ColRtxt,
        stS1ColImage1,
        stS1ColRtxt2,
        stS1ColImage2,
        stS1ColRtxt3,
        stS1ColImage3,
        stS1Hd,
        stS1Hd2,
        stS1Rtxt,
        stS2Hd,
        stS2Hd2,
        stS2Rtxt,
        stS2Image,
        stS3Hd,
        stS3Hd2,
        stS3Rtxt,
        stS3Rtxt2,
        stS4Btn,
        stS4Btn2,
        stS4Btn3,
        stS4Hd,
        stS4Rtxt,
        stS5Hd,
        stS5Rtxt,
    } = page


    return (
        <Layout
            IsHeaderImageDark={false}
            navColour={"white"}>
            <head>
                <title>{metaData.title}</title>
                <meta charset="utf-8" />
                <meta name="description" content={metaData.description} />
                <meta name="keywords" content={metaData.keywords.keywords} />
                <link rel="alternate" hreflang="de" href="https://wunderloop.io/de/sustainability" />
        <link rel="alternate" hreflang="en" href="https://wunderloop.io/en/sustainability" />
            </head>

            <section className="bg-white">
                <div className="head-container2">
                    <div className="row align-items-center">
                        <div className="col-lg-8">
                            <div className="row">
                                <h1 className="align-top h1-small h1-green">
                                    {stHHd2}
                                </h1>
                            </div>
                            <div className="row pt-4">
                                <h1 className="h1-largeMedium2 pt-3 h1-darkBlue">
                                    {stHHd}
                                </h1>
                            </div>
                            <div className="row pt-4">
                                <h2 className="h2-large pt-1 text-darkgray">
                                    {documentToReactComponents(stHRtxt.json, options)}
                                </h2>
                            </div>
                            <div className="row pt-4">
                                <div className="text-center">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--outlineGreen'
                                        buttonSize='btn--large'
                                        href={config.navigationURL_register}
                                    >
                                        {stS4Btn}{" "}
                                        <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                    </Button>
                                </div>
                                <div className="text-center">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryGreen'
                                        buttonSize='btn--large'
                                        href={config.navigationURL_contactSales}
                                    >
                                        {stS4Btn2}{" "}
                                        <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 bg-white">

                        </div>
                    </div>
                    <div className="row pt-5 text-center justify-content-center">
                        <div className="text-center justify-content-center">
                            <img
                                style={{ width: "100vw" }}
                                src={stHImage.file.url}
                                className="img-fluid"
                                alt={stHImage.title} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-lightgray">
                <div className="sectionFit-container">
                    <div className="row flex-column">
                        <div className="col-lg-8">
                            <div className="row">
                                <h1 className="h1-small h1-green">
                                    {stS1Hd2}
                                </h1>
                            </div>
                            <div className="row pt-4">
                                <h1 className="pt-2 h1-medium h1-darkBlue">
                                    {stS1Hd}
                                </h1>
                            </div>
                            <div className="row mb-4 pt-3">
                                <h2 className="h2-large text-darkgray pr-5">
                                    {documentToReactComponents(stS1Rtxt.json, options)}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-5 pl-0 align-items-center">
                        <div className="col-lg-6 pl-0 pr-5 pb-5">
                            <p className="h2-large text-darkgray pt-2 pr-5">
                                {documentToReactComponents(stS1ColRtxt.json, options)}
                            </p>
                        </div>
                        <div className="col-lg-6 pl-5 pr-5 pb-5">
                            <div className="text-center">
                                <img src={stS1ColImage1.file.url} className="img-fluid" alt={stS1ColImage1.title} />
                            </div>
                        </div>
                    </div>
                    <div className="row pt-5 pl-0">
                        <div className="col-lg-6 pl-0 pr-5 pb-5">
                            <p className="h2-large text-darkgray pt-2 pr-5">
                                {documentToReactComponents(stS1ColRtxt2.json, options)}
                            </p>

                            <div className="text-center pt-5 pr-5">
                                <div className="text-center pt-5 pr-5">
                                    <img src={stS1ColImage2.file.url} className="img-fluid" alt={stS1ColImage2.title} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pl-4 pr-5 pb-5">
                            <p className="h2-large text-darkgray pt-2 pr-5">
                                {documentToReactComponents(stS1ColRtxt3.json, options)}
                            </p>
                            <div className="text-center">
                                <img src={stS1ColImage3.file.url} className="img-fluid" alt={stS1ColImage3.title} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-white">
                <div className="sectionFit-container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="row">
                                <h1 className="h1-small h1-green">
                                    {stS2Hd2}
                                </h1>
                            </div>
                            <div className="row pt-5">
                                <h1 className="h1-medium h1-darkBlue">
                                    {stS2Hd}
                                </h1>
                            </div>
                            <div className="row mb-4 pt-2">
                                <h2 className="h2-large text-darkgray pt-4 pr-5">
                                    {documentToReactComponents(stS2Rtxt.json, options)}
                                </h2>
                            </div>
                            <div className="row pt-4">
                                <div className="text-center">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--outlineGreen'
                                        buttonSize='btn--large'
                                        href={config.navigationURL_register}
                                    >
                                        {stS4Btn}{" "}
                                        <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                    </Button>
                                </div>
                                <div className="text-center">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryGreen'
                                        buttonSize='btn--large'
                                        href={config.navigationURL_contactSales}
                                    >
                                        {stS4Btn2}{" "}
                                        <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">


                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-lightgray">
                <div className="sectionFit-container">
                    <div className="row text-center justify-content-center">
                        <h1 className="align-top h1-small h1-green">
                            {stS3Hd2}
                        </h1>
                    </div>
                    <div className="text-center pb-4 row justify-content-center text-center pt-5">
                        <h1 className="align-top h1-medium h1-darkBlue text-center h1-middle">
                            {stS3Hd}
                        </h1>
                    </div>
                    <div className="row mb-4 pt-2">
                        <div className="col-lg-6 pl-0 pr-5 pb-5">
                            <h2 className="h2-large text-darkgray pt-4">
                                {documentToReactComponents(stS3Rtxt.json, options)}
                            </h2>
                        </div>
                        <div className="col-lg-6 pl-4 pr-5 pb-5">
                            <h2 className="h2-large text-darkgray pt-4">
                                {documentToReactComponents(stS3Rtxt2.json, options)}
                            </h2>
                        </div>

                    </div>
                </div>
            </section>

            <section className="bg-white">
                <div className="sectionFit-container">
                    <div className="row pb-4 align-items-center">
                        <div className="col-lg-8">
                            <div className="row pt-5">
                                <h1 className="h1-medium h1-darkBlue">
                                    {stS4Hd}
                                </h1>
                            </div>
                            <div className="row mb-4 pt-2">
                                <h2 className="h2-large text-darkgray pt-4 pr-5">
                                    {documentToReactComponents(stS4Rtxt.json, options)}
                                </h2>
                            </div>
                            <div className="row">
                                <div className="text-center">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--outlineGreen'
                                        buttonSize='btn--large'
                                        href={config.navigationURL_aboutUs}
                                    >
                                        {stS4Btn3}{" "}
                                        <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                    </Button>
                                </div>
                                <div className="text-center">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--primaryGreen'
                                        buttonSize='btn--large'
                                        href={config.navigationURL_contactUs}
                                    >
                                        {stS4Btn2}{" "}
                                        <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 pt-5">
                            <img src={stS2Image.file.url} className="img-fluid" alt={stS2Image.title} />
                        </div>
                    </div>

                    <div className="row pt-5">
                        <div className="colBox6">
                            <h3 className="h3-small h1-darkBlue ">
                                {stS5Hd}
                            </h3>
                            <p className="p-small2 text-darkgray pl-3 pr-5">
                                {documentToReactComponents(stS5Rtxt.json, options)}
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </Layout >
    );
}


export const query = graphql`
query($locale: String!) {
    allContentfulZSustainability(
        filter: {
        node_locale: { eq: $locale }
    }) {
        edges {
            node {
                id
                metaData {
                    slug
                    title
                    description
                    keywords {
                        keywords
                    }
                }
                stHHd
                stHHd2
                stHImage {
                    description
                    file {
                        url
                        fileName
                    }
                    title
                }
                stHRtxt {
                    json
                }
                stS1ColRtxt {
                    json
                }
                stS1ColImage1 {
                    description
                    file {
                        url
                        fileName
                    }
                    title
                }
                stS1ColRtxt2 {
                    json
                }
                stS1ColImage2 {
                    description
                    file {
                        url
                        fileName
                    }
                    title
                }
                stS1ColRtxt3 {
                    json
                }
                stS1ColImage3 {
                    description
                    file {
                        url
                        fileName
                    }
                    title
                }
                stS1Hd
                stS1Hd2
                stS1Rtxt {
                    json
                }
                stS2Hd
                stS2Hd2
                stS2Rtxt {
                    json
                }
                stS2Image {
                    description
                    file {
                        url
                        fileName
                    }
                    title
                }
                stS3Hd
                stS3Hd2
                stS3Rtxt {
                    json
                }
                stS3Rtxt2 {
                    json
                }
                stS4Btn
                stS4Btn2
                stS4Btn3
                stS4Hd
                stS4Rtxt {
                    json
                }
                stS5Hd
                stS5Rtxt {
                    json
                }
            }
        }
    }
}    `
